.contact-section {
  padding: 6.875rem 0 6.875rem 0;
  background: url("../../assets/images/abs-bg7.png");
  position: relative;
  background-position: center bottom;
}
.contact-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #fff;
  opacity: 0.2;
}
.contact-section .heading-social-box {
  position: relative;
  display: flex;
  flex-direction: column;
}
.contact-section .social-list {
  position: relative;
  display: flex;
  gap: 0.625rem;
}
.contact-section .social-list li {
  height: 3rem;
  width: 3rem;
  font-size: 1.125rem;
  line-height: 3rem;
  border-radius: 0;
  background: #b98e75;
  transition: all 300ms ease-out 0s;
}
.contact-section .social-list li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.contact-section .social-list li:hover {
  background: #111;
}
.contact-block {
  background: #fbf6f3;
  padding: 1.875rem 1.875rem;
  border: 0.0625rem solid #ece9e3;
  margin-bottom: 1.875rem;
  display: flex;
}

.contact-icon svg {
  width: 4rem;
  height: 4rem;
  background: #b98e75;
  text-align: center;
  line-height: 4rem;
  display: inline-block;
  border-radius: 0.375rem;
  fill: #fff;
  padding: 0.625rem;
}
.contact-details {
  margin-left: 1.875rem;
}
.contact-details h5 {
  margin-bottom: 0.625rem;
}
.contact-details p {
  margin-bottom: 0;
}
.contact-form .form-group {
  margin-bottom: 1.5625rem;
}

.contact-form .form-control {
  box-shadow: none;
  outline: none;
  background: #fbf6f3;
  border: 0.0625rem solid #ece9e3;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #252531;
  height: auto;
  padding: 1.5625rem 1.25rem;
  resize: none;
  min-width: 100%;
  height: 3.5rem;
  border-radius: 0rem;
}

.contact-form .form-control:focus {
  border-color: #b98e75;
  background: #fbf6f3;
}

.contact-form textarea.form-control {
  padding-top: 1rem;
  display: inline-block;
  min-height: 11.875rem;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 100%;
  width: 100%;
}
.mapouter.fixed-height {
  height: 27.5rem;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: auto;
}
.mapouter iframe {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .heading-box h2 {
    font-size: 1.6rem;
  }
  .contact-section {
    padding: 1.875rem 0 1.875rem 0;
  }
}
