.project-details-page {
  padding-bottom: 5rem;
  padding-top: 6.875rem;
}
.small-img {
  margin-top: 49px;
}
.project-sidebar-widget .project-sidebar {
  padding: 1.5rem 1.8125rem 1.5rem 1.8125rem;
  margin-bottom: 2.375rem;
  background: #252531;
}
.project-sidebar-widget .project-sidebar .project-info-list {
  display: flex;
  justify-content: space-evenly;
}
.project-sidebar-widget .project-sidebar .project-info-list li {
  color: #a7a8b4;
}

.project-sidebar-widget .project-sidebar .project-info-list li .title {
  color: #b98e75;
  display: block;
}

.order-list svg {
  color: #b98e75;
  margin-right: 0.9375rem;
}
.cs-btn-one {
  position: relative;
  display: inline-block;
  text-align: center;
  background-color: #b98e75;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #ffffff;
  padding: 0.875rem 2.875rem;
  font-family: var(--body-font);
  font-weight: 500;
  overflow: hidden;
  border-radius: 0.3125rem;
  border: 0.0625rem solid transparent;
  cursor: pointer;
  letter-spacing: 0.0313rem;
  transition: all 300ms ease-out 0s;
}
.cs-btn-one:hover {
  background: #111;
  color: #ffffff;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.navigation-links {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
}

.navigation-links .nav-previous a:hover i {
  margin-right: 0.1875rem;
}

.mrt--110 {
  margin-top: -5.625rem;
}
.carousel-projects {
  height: 30vh !important;
}
.project-thumb {
  height: 60vh;
}
.project-thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.carousel-projects .carousel-item {
  height: 100% !important;
}
.carousel-projects .carousel-control-prev,
.carousel-projects .carousel-control-next,
.carousel-projects:hover .carousel-control-next,
.carousel-projects:hover .carousel-control-prev {
  width: 40px !important;
  opacity: 1 !important;
}

.carousel-projects .carousel-item img {
  height: 100%;
}
@media only screen and (min-width: 20rem) and (max-width: 87.5rem) {
  .order-list.primary-color {
    font-size: 0.9375rem;
  }
  .order-list svg {
    margin-right: 0.4375rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .project-details-page {
    padding-bottom: 2rem;
    padding-top: 2.875rem;
  }
  .project-sidebar-widget .project-sidebar .project-info-list {
    flex-direction: column;
  }
  .list-column {
    flex-direction: column;
  }
  .page-title-section {
    min-height: 17.3125rem;
    padding: 2.5625rem 0;
  }
  .page-title-section .breadcrumb-area .page-title {
    font-size: 2.575rem;
  }
  .mrt--110 {
    margin-top: -1.25rem;
  }
  .cs-btn-one {
    padding: 0.575rem 0.875rem 0.575rem 0.875rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 75rem) {
  .result-heading {
    margin-top: 1.875rem;
  }
}
