.testimonial-container {
  background: url("../../assets/images/abs-bg5.png") no-repeat;
  background-size: cover;
  background-position: center top;
  padding-bottom: 6.875rem;
  padding-top: 1.563rem;
  position: relative;
  background-color: #fbf6f3;
}

.testimonial-container .carousel {
  margin-left: 11.875rem;
  margin-top: 3.75rem;
  height: 39vh;
}
.testimonial-container:before {
  content: "";
  position: absolute;
  background: #b98e75;
  height: 100%;
  width: 40.3125rem;
  top: 0;
  left: -0.0625rem;
  z-index: 1;
  opacity: 0.92;
}
.testimonial-section-obj1 {
  position: absolute;
  bottom: 1.875rem;
  width: 20rem;
  right: 0;
  animation: float-obj-x infinite 3s;
}
.heading-container {
  padding-left: 31.25rem;
}
.testimonial-container::after {
  background: url(../../assets/images/1.jpg);
  background-size: auto;
  background-size: cover;
  content: "";
  height: 100%;
  width: 40.3125rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.testimonial-container .horizontalSlider___281Ls {
  z-index: 99;
  margin-right: 10.125rem;
}
.testimonial-container .slide___3-Nqo {
  padding-bottom: auto;
}
.testimonial-container .carousel .backbtn,
.testimonial-container .carousel .nextbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  height: 4.375rem;
  left: 2.8125rem;
  line-height: 3.125rem;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 3.125rem;
  background: #b98e75;
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -moz-transition: all 600ms ease 0ms;
  -webkit-transition: all 600ms ease 0ms;
  -ms-transition: all 600ms ease 0ms;
  -o-transition: all 600ms ease 0ms;
  transition: all 600ms ease 0ms;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  color: #fbf6f3;
}
.testimonial-container .carousel .nextbtn {
  left: auto;
  right: 14.8125rem;
}
.testimonial-container .carousel:hover .backbtn {
  left: 0.3125rem;
}
.testimonial-container .carousel:hover .nextbtn {
  right: 12.3rem;
  left: auto;
}
.testimonial-container .carousel:hover .backbtn,
.testimonial-container .carousel:hover .nextbtn {
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1000px) and (max-width: 1600px) {
  .heading-container {
    padding-left: 23.25rem;
    z-index: 4;
    position: relative;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1000px) {
  .testimonial-container:before,
  .testimonial-container::after {
    display: none;
  }
  .heading-container {
    padding: 0;
  }
  .testimonial-container .carousel {
    margin-left: 30px;
  }
  .testimonial-container .horizontalSlider___281Ls {
    margin-right: 30px;
  }
  .nextbtn {
    display: none;
  }
}
