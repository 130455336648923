.company-facts {
  padding: 2.5rem 0;
}
.counter {
  font-size: 3.625rem;
  -webkit-text-stroke: 0.0625rem #b98e75;
  -webkit-text-fill-color: #e092653d;
  font-weight: 800;
  width: 8.125rem;
  text-align: center;
}

.factsIcon {
  font-size: 6.25rem;
  color: black;
  position: absolute;
  left: 30%;
  z-index: -1;
  color: #b98e75;
  opacity: 0.07;
}

.animatedImage {
  animation: float-obj-x infinite 4s;
}

.companyItems:hover .factsIcon {
  animation: pulse 1s infinite;
}
.companyData {
  height: 100%;
  justify-content: space-around;
}

.companyItems {
  width: 17.5%;
  align-items: center;
}

.companyTitle {
  font-weight: 500;
}

@media only screen and (min-width: 20rem) and (max-width: 87.5rem) {
  .companyItems {
    width: auto;
  }
  .companyData {
    padding: 0 2.5rem;
    flex-wrap: wrap;
  }
  .counter {
    text-align: left;
  }
}

@media only screen and (min-width: 20rem) and (max-width: 45.625rem) {
  .companyData {
    justify-content: start;
  }
  .companyTitle {
    font-size: 1.2rem;
  }
  .factsIcon {
    display: none;
  }
  .companyItems {
    width: auto;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 62rem) {
  .companyItems {
    width: 100%;
  }
}
