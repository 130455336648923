.call-to-action {
  position: relative;
  display: block;
}
.call-to-action .call-to-action-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.8125rem 4.6875rem 2.8125rem;
  background-color: #b98e75;
  overflow: hidden;
}
.call-to-action .call-to-action-inner .call-to-action-left {
  position: relative;
  display: flex;
  align-items: center;
}
.call-to-action
  .call-to-action-inner
  .call-to-action-left
  .call-to-action-content {
  position: relative;
}
.call-to-action
  .call-to-action-inner
  .call-to-action-left
  .call-to-action-content
  .call-to-action-sub-title {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}
.call-to-action
  .call-to-action-inner
  .call-to-action-left
  .call-to-action-content
  .call-to-action-title {
  font-size: 2.25rem;
  color: #fff;
  font-weight: 700;
  line-height: 2.9375rem;
}
.call-to-action .call-to-action-inner::after {
  content: "";
  background-image: url("../../assets/images/wave2.png");
  background-size: cover;
  background-position: center left;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.2;
  filter: brightness(0.3);
  animation: heartbeat 1.5s infinite alternate;
}

@media only screen and (min-width: 20rem) and (max-width: 74.9375rem) {
  .call-to-action .call-to-action-inner {
    padding: 1.8125rem 1.6875rem 1.8125rem;
  }
  .call-to-action
    .call-to-action-inner
    .call-to-action-left
    .call-to-action-content
    .call-to-action-title {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .call-to-action
    .call-to-action-inner
    .call-to-action-left
    .call-to-action-content
    .call-to-action-title {
    line-height: 1.9375rem;
  }
  .MuiButton-textPrimary.primary-btn {
    width: 8.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .heading-box .heading {
    padding-left: 0;
  }
}
@media only screen and (max-width: 28.75rem) {
  .call-to-action-inner {
    flex-direction: column;
    text-align: center;
  }
}
