.project-item-style1 {
  margin-right: 0.9375rem;
}
.latesprojectslide .project-item-style1 {
  height: 100%;
}
.latesprojectslide
  .project-item-style1:hover
  .project-item-thumb
  .project-item-details {
  bottom: 4.5rem;
}
.latesprojectslide .project-item-style1 .project-item-thumb {
  height: 100%;
}
.latesprojectslide .project-item-style1 .project-item-thumb img {
  height: 100%;
  object-fit: cover;
}
.project-item-style1 .project-item-thumb {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.project-item-style1:hover .project-item-thumb img {
  -moz-transform: scale(1.2) rotate(3deg);
  -o-transform: scale(1.2) rotate(3deg);
  -ms-transform: scale(1.2) rotate(3deg);
  -webkit-transform: scale(1.2) rotate(3deg);
  transform: scale(1.2) rotate(3deg);
}
.project-item-style1 .project-item-thumb img {
  width: 100%;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  transition: all 900ms ease;
  height: 100%;
  object-fit: cover;
}

.project-item-style1:hover .project-item-thumb .project-item-link-icon a {
  visibility: visible;
  opacity: 1;
  right: 1.75rem;
}
.project-item-style1 .project-item-thumb .project-item-link-icon a {
  position: absolute;
  z-index: 3;
  top: 3.125rem;
  right: -2.125rem;
  width: 3.25rem;
  margin-top: -1.5625rem;
  margin-left: -1.5625rem;
  height: 3.25rem;
  background: #b98e75;
  text-align: center;
  line-height: 3.75rem;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}
.project-item-style1:hover .project-item-thumb .project-item-details {
  opacity: 1;
  bottom: 2.25rem;
  left: 1.875rem;
}
.project-item-style1 .project-item-thumb .project-item-details {
  padding: 0.9375rem;
  position: absolute;
  width: 88%;
  margin: 0 auto;
  left: 1.25rem;
  bottom: 1.25rem;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
  transform-origin: bottom;
  z-index: 2;
  opacity: 0;
}

.project-item-style1
  .project-item-thumb
  .project-item-details
  .project-item-category {
  color: #b98e75;
  padding-left: 2.5rem;
  margin-bottom: 0.3125rem;
  position: relative;
}

.project-item-style1
  .project-item-thumb
  .project-item-details
  .project-item-category::after {
  content: "";
  height: 0.0625rem;
  width: 1.875rem;
  background: #b98e75;
  position: absolute;
  left: 0;
  top: 0.8125rem;
}

.project-item-style1
  .project-item-thumb
  .project-item-details
  .project-item-title
  a {
  color: #fff;
}

.project-item-style1:hover .project-item-thumb::after {
  opacity: 1;
}
.project-item-style1 .project-item-thumb::after {
  position: absolute;
  background: linear-gradient(-60deg, #3d260ddb, #060505cc);
  height: 100%;
  width: 100%;
  content: "";
  left: 0;
  bottom: 0;
  opacity: 0;
  -moz-transition: all 500ms ease-out 0s;
  -webkit-transition: all 500ms ease-out 0s;
  -ms-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}

@media only screen and (min-width: 20rem) and (max-width: 75rem) {
  .project-item-style1 .project-item-thumb {
    /* height: auto; */
  }
}
