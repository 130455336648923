.services-container {
  background: url(../../assets/images/abs-bg2.png);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 12.5rem;
  padding-top: 1.563rem;
  position: relative;
  background-color: #fbf6f3;
}
.lamp-box {
  position: absolute;
  top: 0;
  width: 5.313rem;
  left: 6.25rem;
  animation: float-obj-x infinite 3s;
  opacity: 0.25;
}

.service-container-header {
  display: flex;
  align-items: center;
}
.service-container-header p,
.heading-box {
  flex: 1;
}

.service-container-header p {
  margin-left: 20px;
}
