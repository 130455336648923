@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@600&display=swap");
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
