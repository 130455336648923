.testimonial-item {
  background: #fff;
  padding: 2.8125rem 3.1875rem 1.25rem 3.1875rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-right: 1.875rem;
}

.testimonial-item .testimonial-thumb {
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem;
  box-shadow: 0 0 0 0.25rem #b98e75;
  height: 40px;
  width: 40px;
}

.testimonial-item .testimonial-thumb img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.testimonial-item .testimonial-content .client-name {
  margin-bottom: 0.3125rem;
  z-index: 1;
  position: relative;
}
.testimonial-item .testimonial-content .client-designation {
  font-size: 1rem;
  color: var(--primary-color);
  letter-spacing: 0rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
  z-index: 1;
  position: relative;
  color: #b98e75;
}
.testimonial-item::after {
  position: absolute;
  background: url("../../../assets/images/2.png");
  background-repeat: repeat;
  content: "";
  height: 14.0625rem;
  width: 18.75rem;
  right: -1.875rem;
  bottom: 0;
  background-repeat: no-repeat;
  opacity: 0.2;
  transform: scale(1.3);
}

.testimonial-item svg {
  position: absolute;
  top: 0;
  left: 1.875rem;
  text-align: center;
  line-height: 0;
  color: #b98e75;
  font-size: 6rem;
  opacity: 0.15;
  z-index: 0;
}
