.container-box {
  background: url(../../assets/images/abs-bg4.png) no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding-top: 18.75rem;
  padding-bottom: 6.56rem;
  background-color: #252531;
  position: relative;
}
.second-container {
  padding-top: 2.5rem;
}
.title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-box h5 {
  color: #b98e75;
  margin-bottom: 0.625rem;
  font-size: 1.25rem;
  z-index: 2;
  font-family: "Figtree", sans-serif;
}
.title-box h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
  color: #fff;
  font-family: "Figtree", sans-serif;
  z-index: 2;
}

.title-box .big-text {
  font-size: 10rem;
  position: absolute;
  opacity: 1;
  left: 54.3%;
  right: 0;
  top: 21%;
  color: #353542;
  font-weight: 500;
  z-index: -1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0.1875rem;
  -webkit-text-stroke-color: #30303b;
  z-index: 1;
  transform: translateX(-50%);
}

.working-process-wrapper {
  position: relative;
}
.working-process-wrapper .working-process-shape {
  position: absolute;
  top: -3.125rem;
  left: 13.4375rem;
  opacity: 0.1;
}
.process-item-container {
  display: flex;
  flex-wrap: wrap;
}
