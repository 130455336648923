.projectDetailsContainer {
  height: 100%;
}
.form-textarea {
  border: 1px solid #6c757d;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
}

.input-parent-box,
.input-parent-box .MuiFormControl-root,
.input-parent-box input {
  width: 100%;
}

.img-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #6c757d;
  padding: 20px;
  background: #b98e75;
  color: aliceblue;
  box-shadow: 0 0 9px #6c757d;
  cursor: pointer;
}
.img-box label {
  width: 100%;
  text-align: center;
}
.form-btn {
  padding: 20px;
  border: 1px solid #6c757d;
  width: 100%;
  box-shadow: 0 0 4px #6c757d;
  transition: all ease 0.4s;
}
.form-btn:hover {
  box-shadow: none;
}

.project-form-details form {
  width: 100%;
}
.feature-parent {
  display: flex;
  flex-direction: column;
}
.circular-progress {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #888888c0;
  z-index: 1000;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .project-form-details form {
    width: 100%;
  }
}
