.main-container {
  background-image: url(../../assets/images/abs-bg3.png);
  padding: 6.563rem 0;
  background-size: cover;
  background-position: center bottom;
  font-family: "Rubik", sans-serif;
}
.main-container .data-box .left-box {
  position: relative;
  width: 50%;
  overflow: hidden;
}
.main-container .data-box .left-box::after {
  content: "";
  position: absolute;
  background: #b98e75;
  width: 1.25rem;
  height: 18.75rem;
  left: 0.938rem;
  top: 0;
}
.main-container .data-box {
  display: flex;
}
.main-container .data-box .left-box .small-img-box {
  position: absolute;
  left: 0;
  width: 26.25rem;
  bottom: 0;
  border: 0.938rem solid #ffffff;
  z-index: 3;
}

.main-container .data-box .left-box .large-img-box .large-img {
  width: 30rem;
  margin-left: 4.375rem;
  position: relative;
  overflow: hidden;
}

.main-container .data-box .left-box .large-img-box::before {
  position: absolute;
  top: 0;
  left: -80%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
  z-index: 1;
}
.main-container .data-box .left-box .large-img-box:hover:before {
  animation: shine 2.3s;
}

.main-container .data-box .right-box {
  width: 50%;
}

.main-container .data-box .right-box .about-text-block {
  background: #fbf6f3;
  padding: 1.125rem 1.875rem;
  border-bottom: 0.125rem solid #b98e75;
  color: #2d3941;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.listbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listbox ul {
  padding: 0;
}

.listbox ul li {
  display: inherit;
  position: relative;
  margin: 0.75rem 0;
  margin-top: 0.75rem;
}

.listbox ul li svg {
  margin-right: 1.25rem;
  color: #b98e75;
  font-weight: 800;
}

.feature-box-container .features-box {
  margin-bottom: 0.938rem;
  display: flex;
}

.features-box .feature-content h4 {
  margin-bottom: 0.625rem;
}

.features-box .feature-content p {
  margin: 0 0 1.25rem;
  line-height: 1.8em;
}

.experience {
  background: url(../../assets/images/2.jpg);
  padding: 2.5rem;
  text-align: center;
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.experience .experience-text {
  z-index: 1;
  position: relative;
  color: #fff;
  margin-bottom: 0.313rem;
}
.experience .experience-year {
  z-index: 1;
  position: relative;
  color: #fff;
  font-size: 6.875rem;
  line-height: 4.688rem;
  -webkit-text-fill-color: #bc917600;
  -webkit-text-stroke-width: 0.063rem;
  -webkit-text-stroke-color: #ffffff;
  font-family: "Rubik", sans-serif;
}

.yoe-box {
  display: flex;
  gap: 0.125rem;
}

.experience::after {
  content: "";
  background: rgba(185, 142, 117, 0.92);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}

.features-box .feature-icon svg {
  color: #b98e75;
  font-size: 3.125rem;
  margin-right: 1.188rem;
}

@media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  .data-box {
    flex-direction: column;
    align-items: center;
  }
  .main-container .data-box .right-box {
    width: 100%;
  }
  .yoe-box {
    justify-content: space-between;
  }
  .main-container .data-box .left-box .small-img-box {
    width: 17rem;
  }
  .main-container {
    padding: 1.5rem 0;
  }
}

@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .main-container .data-box {
    flex-direction: column;
  }
  .main-container {
    padding: 1.5rem 0;
  }

  .main-container .data-box .left-box,
  .main-container .data-box .right-box {
    width: 100%;
  }
  .yoe-box {
    flex-direction: column;
  }
  .listbox {
    display: none;
  }
  .right-box {
    margin-top: 2rem;
  }
  .experience .experience-year {
    margin-top: 2rem;
  }
  .main-container .data-box .left-box .small-img-box {
    display: none;
  }
}
