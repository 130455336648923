.whatweoffer {
  background: url("../../assets/images/abs-bg4.png") no-repeat;
  background-size: cover;
  background-position: center bottom;
  position: relative;
  padding-bottom: 4.6875rem;
  padding-top: 6.875rem;
}

.whatweoffer .section-content {
  padding: 0;
}
.whatweoffer::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: #252531;
  z-index: -1;
}

.whatweoffer .heading-box {
  color: #fff;
}
.whatweoffer .desc {
  color: #fff;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  padding-left: 0;
}
.video-block {
  display: inline-block;
}
.video-block .video-link {
  text-align: center;
}
.video-block .video-link a {
  display: inline-block;
  position: relative;
}
.video-block .video-link a svg {
  display: inline-block;
  position: relative;
  height: 4.6875rem;
  width: 4.6875rem;
  line-height: 4.6875rem;
  background: #b98e75;
  border-radius: 50%;
  z-index: 3;
  border: 0.0625rem solid #ffffff1a;
}
.video-block .video-link a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ad836ab8;
  border-radius: 50%;
  animation: pulse-border-2 1s ease-out infinite;
  z-index: 0;
  border: 0.0625rem solid #b98e75;
}
.shine-effect {
  position: relative;
  overflow: hidden;
}

.shine-effect::before {
  position: absolute;
  top: 0;
  left: -80%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
  z-index: 1;
}
.shine-effect:hover::before {
  -webkit-animation: shine 1.3s;
  animation: shine 1.3s;
}
.whatweoffer-category-container {
  margin-top: 7.5rem;
  margin-bottom: 1.875rem;
}

.whatweoffer-category-container .categories-container {
  background: transparent;
  width: 100%;
}
.whatweoffer-category-container .categories-container .category {
  position: relative;
  background: #343441;
  padding: 1.5625rem 1.25rem 1.875rem 1.25rem;
  margin-bottom: 1.5625rem;
  overflow: hidden;
}

.whatweoffer-category-container .categories-container .icon-box {
  background: transparent;
  transition: all 400ms ease-out 0s;
}

.whatweoffer .categories-container .category:hover .icon-box {
  -moz-transform: scale(-1) rotate(180deg);
  -o-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}
.whatweoffer-category-container .categories-container .feature-box {
  background: none;
  padding: 2.81rem 0 1.56rem 0;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .whatweoffer {
    padding-bottom: 0;
  }
}
.whatweoffer-category-container {
  margin-top: 2.5rem;
}
