.author-box {
  padding-top: 6.5625rem;
  padding-bottom: 2.3125rem;
}
.author-block {
  margin-bottom: 1.875rem;
}
.author-upper-part {
  position: relative;
  overflow: hidden;
}
.author-block .author-upper-part::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #252531;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  -moz-transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.author-block:hover .author-upper-part::before {
  opacity: 0.5;
}
.author-block .author-upper-part img {
  -moz-transition: all 0.4s ease-out 0s;
  -webkit-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  height: 19.375rem;
  width: 100%;
  object-position: 0 -7.125rem;
  object-fit: cover;
}

.author-block:hover .author-upper-part img {
  -moz-transform: scale(1.08);
  -o-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
.social-list {
  position: absolute;
  bottom: 1.875rem;
  right: 0.175rem;
  z-index: 1;
}
.author-block .author-upper-part .social-list.vertical-style li {
  float: none;
  display: list-item;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  opacity: 0;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transition-delay: 0s;
  transform: translateY(0.3125rem);
  margin-right: 0.625rem;
  height: 2.125rem;
  width: 2.125rem;
  font-size: 0.875rem;
  line-height: 2.125rem;
  border-radius: 0.25rem;
  background: #b98e75;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.author-block .author-upper-part .social-list.vertical-style li svg {
  font-size: 1.1rem;
}
.author-block .author-upper-part .social-list.vertical-style li:nth-child(1) {
  transform: translateY(0.9375rem);
}
.author-block:hover .author-upper-part .social-list.vertical-style li {
  opacity: 1;
  transform: translateY(0rem);
  transition-delay: 0.15s;
}

.author-block:hover
  .author-upper-part
  .social-list.vertical-style
  li:nth-child(1) {
  transform: translateY(0rem);
  transition-delay: 0.3s;
}

.author-block:hover .author-upper-part::after {
  -moz-transform: translateY(-160%) skewY(-65deg);
  -o-transform: translateY(-160%) skewY(-65deg);
  -ms-transform: translateY(-160%) skewY(-65deg);
  -webkit-transform: translateY(-160%) skewY(-65deg);
  transform: translateY(-160%) skewY(-65deg);
  opacity: 0;
}
.author-block .author-upper-part::after {
  content: "";
  position: absolute;
  background: #b98e75;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.15;
  -moz-transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  -moz-transform: skewY(-65deg);
  -o-transform: skewY(-65deg);
  -ms-transform: skewY(-65deg);
  -webkit-transform: skewY(-65deg);
  transform: skewY(-65deg);
}
.author-bottom-part {
  background: #fbf6f3;
  padding: 1.25rem;
  position: relative;
  text-align: center;
}
.author-bottom-part::before {
  background: #b98e75;
  height: 3.125rem;
  width: 0.125rem;
  right: 0;
  bottom: 1.25rem;
  content: "";
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  z-index: 0;
}
.author-block:hover .author-bottom-part::after,
.author-block:hover .author-bottom-part::before {
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
}
.author-bottom-part::after {
  background: #b98e75;
  height: 3.125rem;
  width: 0.125rem;
  left: 0;
  top: 1.875rem;
  content: "";
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  z-index: 0;
}

.author-title {
  margin-bottom: 0.3125rem;
}

.author-bottom-part .designation {
  color: #b98e75;
}

.author-block:hover .author-bottom-part .author-title,
.author-block:hover .author-bottom-part .designation {
  z-index: 1;
  color: #fff;
  position: relative;
}

@media only screen and (min-width: 62rem) and (max-width: 87.5rem) {
  .author-block .author-upper-part img {
    height: 12.375rem;
    object-position: 0 -5.125rem;
  }
  .desc {
    font-size: 1rem;
  }
  .desc svg:nth-child(2) {
    bottom: -6rem;
  }
}

@media only screen and (min-width: 47.9375rem) and (max-width: 61.9375rem) {
  .author-block .author-upper-part img {
    height: 11.375rem;
    object-position: 0 -5.125rem;
  }
  .desc {
    padding-left: 2.0625rem;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 61.9375rem) {
  .desc svg {
    display: none;
  }
  .desc {
    padding-left: 0;
    font-size: 1rem;
  }
  .author-box {
    padding-top: 1.5625rem;
    padding-bottom: 1.3125rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 75rem) {
  .shine-effect {
    margin-top: 1.875rem;
  }
  .shine-effect img {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .social-list {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .service-link-list {
    margin-bottom: 0 !important;
  }
}
