ul {
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: white;
}

.footerTextColor {
  padding: 4.875rem 4.125rem 0 4.125rem;
  display: flex;
  flex-direction: column;
  color: #bbb7c2;
  position: relative;
  background-image: url(../../assets/images/5.jpg);
  background-color: #2a2a36;
  z-index: 0;
}

.footerTextColor::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #252531;
  opacity: 0.98;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.socialmediaIcons {
  padding: 0.625rem;
  background-color: #303441;
  border-radius: 50%;
}

.servicesListItems {
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
  margin-top: 1.25rem;
}

.ListItem {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  transition: transform 0.3s ease-out;
  transform: translate(0);
}

.ListItem a {
  color: #bbb7c2;
}

.ListItem:hover,
.ListItem a:hover {
  color: #b88e77;
}

.ListItem a:hover {
  transform: translate(0.3125rem);
}
.footerHeading {
  color: white;
  position: relative;
  padding: 1.25rem 0;
}

.footerHeading::before {
  content: "";
  position: absolute;
  border-radius: 0.625rem;
  height: 0.25rem;
  width: 3.625rem;
  bottom: 0;
  background-color: #b88e77;
}

.lineHeight {
  line-height: 1.75rem;
}

.copyRightCard {
  background-color: #303441;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 3.125rem 0 0 0;
  padding: 1.5625rem 0;
  z-index: 9;
}

.contactDetails:hover {
  color: #b88e77;
}

.socialmediaIcons:hover {
  background-color: #b88e77;
}

.backgroundImage {
  position: absolute;
  bottom: 0;
  width: 34.375rem;
  left: 0;
  opacity: 0.06;
}

.backgroundImageSecond {
  position: absolute;
  top: 1.875rem;
  right: 6.25rem;
  animation: float-obj-x infinite 3s;
}
.socialmediaIcons,
.contactDetails,
.ListItem a {
  transition: all ease-in-out 300ms;
}
.footer-logo {
  height: 80px;
  object-fit: cover;
  width: 80px;
}
@media only screen and (min-width: 20rem) and (max-width: 75rem) {
  .servicesListItems,
  .lineHeight {
    /* font-size: .8125rem; */
  }
}
@media only screen and (min-width: 20rem) and (max-width: 62rem) {
  .footer-mobile {
    flex-direction: column;
  }
  .footer-mobile .rxs {
    width: 100%;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .footer-mobile .rxs {
    flex-direction: column;
  }
  .footerTextColor {
    padding: 1.875rem 1.125rem 0 1.125rem;
  }
  .copyRightCard {
    text-align: center;
    margin-top: 1.2rem;
    padding: 1.5625rem;
  }
  .footer-logo {
    height: 55px;
    width: 55px;
  }
}
