.page-title-section {
  background-position: center;
  background-color: #f8f8f8;
  background-image: url(../../assets/images/2.jpg);
  display: flex;
  align-items: end;
  min-height: 26.3125rem;
  position: relative;
  padding: 6.5625rem 0;
}
.page-title-section::after {
  background: linear-gradient(360deg, #000000, #130c05);
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.75;
  z-index: 1;
}
.page-title-section .breadcrumb-area {
  text-align: center;
  z-index: 2;
  position: relative;
}
.page-title-section .breadcrumb-area .page-title {
  color: #fff;
  margin-bottom: 0.9375rem;
  letter-spacing: 0.1875rem;
  font-size: 2.875rem;
}
.page-title-section .breadcrumb-area .breadcrumbs-link {
  display: inline-block;
  background: rgba(255, 255, 255, 0.07);
  padding: 0.5rem 2.5rem;
}
.page-title-section .breadcrumb-area .breadcrumbs-link li {
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
}
.page-title-section .breadcrumb-area .breadcrumbs-link li a {
  color: #fff;
}
.page-title-section .breadcrumb-area .breadcrumbs-link li span {
  margin: 0 0.9375rem;
}
.page-title-section .breadcrumb-area .breadcrumbs-link li.active {
  color: #b98e75;
  text-decoration: underline;
}
