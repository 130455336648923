.whyToChooseUsSection {
  padding: 6.875rem 0;
  background-color: #f8f4f3;
  background-image: url(../../assets/images/abs-bg1.png);
  background-size: 100%;
}
.whyToChooseUsImages {
  position: relative;
}
.video-frame {
  width: 65%;
}
.tilt {
  position: absolute;
  bottom: 2.5rem;
  margin-left: -2.5rem;
}

.whyToChooseUsImages::after {
  background-image: url(../../assets/images/certificate2.png);
  position: absolute;
  top: 2.5rem;
  content: "";
  background-size: 100%;
  width: 9.625rem;
  height: 9.625rem;
  right: 7.5rem;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 0 0.625rem#b8a1942b;
  transform: perspective(75rem) rotateX(0deg) rotateY(0deg);
}
ul {
  list-style-image: url();
}

.whyToChooseUsRightSection {
  flex: 1;
  margin-left: -85px;
}

.whyToChooseUsSection .heading-box h2 {
  font-size: 2.8rem;
  margin-bottom: 2.25rem;
}

.whyToChooseUsRightSection p {
  font-size: 1.2rem;
  color: #555;
  font-weight: 200;
}
.video-range {
  margin-top: 40px;
}
.video-range li {
  font-size: 17.2px;
}

.whyToChooseUsRightSection ul {
  font-size: 0.75rem;
  color: #555;
  font-weight: 200;
}
.whyToChooseUsImages {
  flex: 1;
}
.whyToChooseUsImages img {
  width: 73%;
}
.whyToChooseUsRightSection .MuiSlider-thumb {
  display: none;
}
.whyToChooseUsRightSection .MuiSlider-rail {
  color: #eae4e4;
  height: 0.8125rem !important;
  top: 0.6rem !important;
  opacity: 1 !important;
}
.whyToChooseUsRightSection .MuiSlider-track {
  color: #b98e75;
  height: 0.3125rem !important;
}

.whyToChooseUsRightSection .rangeSlider p {
  font-weight: 600;
}

.rangeSliderContainer {
  margin-top: 1.875rem;
}

@media only screen and (min-width: 320px) and (max-width: 1200px) {
  .mainwhyus {
    flex-direction: column;
  }
  .whyToChooseUsImages {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  .whyToChooseUsImages img {
    width: 43%;
  }
  .whyToChooseUsImages::after {
    right: 10.5rem;
  }
  .tilt {
    bottom: 1.5rem;
    margin-left: -15.5rem;
  }
  .whyToChooseUsRightSection {
    margin-left: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .video-range {
    flex-direction: column;
  }
  .whyToChooseUsImages img {
    width: auto;
  }
  .whyToChooseUsImages::after {
    display: none;
  }
  .secondimg {
    display: none;
  }
  .whyToChooseUsSection {
    padding: 30.8px 15px;
  }

  .whyToChooseUsSection .heading-box h2 {
    font-size: 1.8rem;
    margin-bottom: 1.25rem;
  }
}
