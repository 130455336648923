.section-content {
  padding: 6.875rem 0 5rem 0;
}

.section-content .project-item-style1 {
  margin-right: 0;
  margin-bottom: 1.4375rem;
  height: 100%;
}

.project-testimonial {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 6.875rem;
  padding-top: 6.5625rem;
}

.project-testimonial .heading::before {
  content: "";
  position: absolute;
  bottom: 0.125rem;
  left: 47.6%;
  height: 0.375rem;
  background-color: #efe8de;
  z-index: -1;
  width: 9.1%;
}

.project-testimonial .heading::after {
  display: none;
}
.project-testimonial .carousel {
  width: 100%;
  height: auto;
}

.project-testimonial .carousel .testimonial-item {
  background: #fbf6f3;
  text-align: start;
}

.project-testimonial .testimonial-item svg {
  transform: rotate(180deg);
  top: 1.25rem;
  left: 70%;
}

.project-testimonial .testimonial-thumb {
  /* border-radius: 0; */
}

@media only screen and (min-width: 20rem) and (max-width: 87.5rem) {
  .testimonial-item {
    padding: 1.8125rem 1.1875rem 1.25rem 1.1875rem;
  }
  .project-testimonial .testimonial-item svg {
    left: 60%;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .testimonial-item {
    margin-right: 0;
  }
  .section-content {
    padding: 2.875rem 0 2rem 0;
  }
  .project-testimonial {
    padding-bottom: 0.875rem;
    padding-top: 2.5625rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 997px) {
  .section-content {
    padding: 2.875rem 0 2rem 0;
  }
  .project-item-title {
    font-size: 16px;
  }
  /* .heading-box .heading {
    padding-left: 0;
  } */
  .project-testimonial {
    padding-bottom: 3.875rem;
    padding-top: 3.5625rem;
  }
}
