.error-area {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/404.jpg");
  background-size: cover;
  background-position: center center;
}
.error-area::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background: #fff;
  opacity: 0.95;
}
.error-inner {
  text-align: center;
}

.error-inner .error-title {
  font-size: 11.875rem;
  line-height: 15rem;
}
.error-inner .error-title span {
  color: #b98e75;
}

.error-inner .error-text {
  font-size: 2.5rem;
}

.error-inner p {
  max-width: 34.0625rem;
  margin: 1.25rem auto 1.875rem;
  color: #555555bf;
}

.cs-btn-one {
  font-size: 0.875rem;
  padding: 1.125rem 3.5rem;
  background: #b98e75;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #ffffff;
  padding: 0.875rem 2.875rem;
  font-weight: 500;
  overflow: hidden;
  border-radius: 0.3125rem;
  border: 0.0625rem solid transparent;
  cursor: pointer;
  letter-spacing: 0.0313rem;
}
.cs-btn-one:hover {
  background: #111;
}

@media only screen and (min-width: 20rem) and (max-width: 62rem) {
  .cs-btn-one {
    padding: 0.875rem;
  }
  .error-inner .error-title {
    font-size: 9.875rem;
    line-height: 9rem;
  }
}
