.heading-box .heading {
  position: relative;
  padding-left: 4.375rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
  color: #b98e75;
}

.heading-box .heading::before {
  content: "";
  height: 0.125rem;
  width: 3.125rem;
  position: absolute;
  left: 0;
  bottom: 0.5rem;
  background: #b98e75;
}

.heading-box .heading::after {
  position: absolute;
  content: "";
  height: 0.125rem;
  width: 3.125rem;
  background: #b98e75;
  left: 0.625rem;
  bottom: 0.75rem;
}
.heading-box h2 {
  font-size: 2.6rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.heading-box h2 span {
  color: #b98e75;
}
.desc {
  padding-left: 4.0625rem;
  font-size: 1.375rem;
  position: relative;
}
.desc svg:nth-child(2) {
  transform: rotate(180deg);
  position: absolute;
  bottom: -10rem;
  right: 0;
}

.desc svg:nth-child(1) {
  position: absolute;
  left: 1.125rem;
  top: -7.5rem;
}
.desc svg {
  font-size: 7.5rem;
  opacity: 0.08;
  fill: #b98e75;
  transition: all 0.6s ease;
}

.desc:hover svg {
  font-size: 8.75rem;
}
