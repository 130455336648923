.process-item {
  position: relative;
  display: block;
  text-align: center;
  padding: 0 2.5rem 0;
}
.process-item .process-icon-box {
  position: relative;
  display: block;
  height: 9.375rem;
  width: 9.375rem;
  margin: 0 auto;
}
.process-item .process-icon-box .process-icon {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #353542;
  box-shadow: 0 0 3.563rem 0 rgba(0, 0, 0, 0.1);
  height: 9.25rem;
  width: 9.25rem;
  line-height: 9.25rem;
  margin: 0 auto;
  z-index: 1;
  border-radius: 50%;
}
.process-item .process-icon-box .process-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: #b98e75;
  transform-origin: center;
  transform-style: preserve-3d;
  -moz-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -webkit-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -ms-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -o-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  z-index: -1;
  border-radius: 50%;
}
.process-item .process-icon-box .process-count {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #b98e75;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
}

.process-item .process-icon-box .process-count::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: "";
  bottom: 0;
  font-size: 0.875rem;
  line-height: 2.5rem;
  -moz-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  border-radius: 50%;
}

.process-item:hover .process-icon-box .process-icon::before {
  transform: scale(1.05);
}

.process-item:hover .process-icon-box .process-count::before {
  background-color: #fff;
  z-index: -1;
}
.process-item:hover .process-icon-box .process-count {
  color: #b98e75;
}
.process-item .process-details {
  position: relative;
  display: block;
  margin-top: 1.313rem;
}
.process-item .process-details .process-title {
  margin-bottom: 0.625rem;
  color: #fff;
}
.process-item .process-details .process-text {
  color: #fff;
  margin-bottom: 0;
}
.process-icon svg {
  color: #fff;
  font-size: 3.5rem;
}
@media only screen and (min-width: 20rem) and (max-width: 62rem) {
  .process-item {
    margin-bottom: 1.5rem;
  }
  .title-box .big-text {
    left: 52.3%;
    font-size: 7rem;
  }
  .working-process-shape {
    display: none;
  }
  .container-box {
    padding-bottom: 1.5rem;
    padding-top: 14.75rem;
  }
  .slider::after {
    top: 53%;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .title-box .big-text {
    display: none;
  }
  .title-box h2 {
    font-size: 1.625rem;
  }
}
@media only screen and (min-width: 25rem) and (max-width: 37.5rem) {
  .slider::after {
    top: 55%;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 25rem) {
  .slider::after {
    top: 61%;
  }
}
