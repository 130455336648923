.headerContainer {
  justify-content: space-between;
  height: 6.188rem;
}

.headerLink {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  color: white;
  transition: all 400ms ease;
}

.headerLink:hover {
  color: #b98e75;
}

.headerWrapper header {
  background: transparent;
  border-bottom: 0.063rem solid #ffffff0d;
  box-shadow: none;
  transition: all 400ms ease;
}

.headerWrapper .headerscroll {
  background-color: #252531;
}

.headerWrapper {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.callAnytimeText {
  color: #cccccc;
  font-weight: 300;
}

.menuItemTextColor {
  transition: all 400ms ease;
}

.menuItemTextColor:hover {
  background-color: #b98e75 !important;
}

.menuItemTextColor:hover a {
  color: white !important;
}

#basic-menu .MuiPaper-root {
  top: 6.25rem !important;
  border-radius: 0 !important;
  width: 14.5rem;
}

.menuItemHeaderLink::after {
  content: "+";
  height: 0.75rem;
  width: 0.625rem;
  margin-left: 0.3125rem;
}

.headerTabs {
  display: flex;
}

.hamburgerMenu {
  display: none;
}
.headerLogo {
  height: 80px;
  object-fit: cover;
  width: 80px;
}

.mobile-animate {
  margin-top: 20px !important;
}
@media only screen and (max-width: 992px) {
  .headerContainer {
    height: 75px;
  }
  .hamburgerMenu {
    display: flex;
  }
  .headerLogo {
    height: 55px;
    width: 55px;
  }

  .companyData {
    flex-direction: column;
  }
  .callAnytime {
    display: none;
  }
  .callAnytimeIcon {
    width: 35px;
    height: 35px;
  }

  .listItem {
    display: flex;
    transition: all 300ms ease-out 0s;
  }
  .mobileSocialMediaIcons {
    padding: 0.625rem;
    background-color: #b98e75;
    border-radius: 50%;
  }
  .headerTabs {
    display: none;
  }
}
.css-zrd4ek-MuiList-root {
  height: 100vh;
}

.mobile-appbar {
  box-shadow: none !important;
}

.mobile-animate {
  height: 100vh;
}
