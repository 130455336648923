.carousel {
  height: 94vh;
  position: relative;
}
.carousel .carousel-inner {
  height: 100%;
}
.carousel .carousel-inner .slide-item {
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  overflow: hidden;
  background-color: #151130;
  height: 100%;
}

.carousel .carousel-inner .slide-item img {
  object-fit: cover;
  height: 100%;
}
.carousel .carousel-inner .slide-item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 105%;
  background: linear-gradient(125deg, #1b1004db 30%, rgba(24, 13, 1, 0.4) 80%);
  top: 0;
  right: 0;
  z-index: 2;
  opacity: 0.9;
}

.carousel .carousel-inner .slide-item::after {
  content: "";
  background: #3d3124f0;
  width: 50%;
  height: 20%;
  position: absolute;
  right: 0;
  left: 75%;
  top: 95%;
  bottom: 10rem;
  mix-blend-mode: color-dodge;
  z-index: 2;
  transform: skewY(15deg) translateX(30rem);
  transition-delay: 0.8s;
  opacity: 1;
  transition-duration: 1200ms;
}

.slide-item.active.slide-item::after {
  opacity: 1;
  transform: skewY(15deg) translateX(0);
  background: #3d3124f0;
}

.carousel .carousel-inner .slide-item .slide-item-caption {
  z-index: 2;
  position: absolute;
  top: 26%;
  right: 50%;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  left: 8%;
}

.slide-item-caption h3 {
  position: relative;
  display: block;
  font-size: 4.8rem;
  line-height: 5.5rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 1.8rem;
  opacity: 0;
  max-width: 50rem;
  z-index: 11;
  color: #fff;
  -moz-transform: translateY(8.2rem);
  -o-transform: translateY(8.2rem);
  -ms-transform: translateY(8.2rem);
  -webkit-transform: translateY(8.2rem);
  transform: translateY(8.2rem);
  -moz-transition: all 1000ms ease;
  -webkit-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  transition-delay: 0s;
}
.slide-item.active .slide-item-caption h3 {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.slide-item-caption h3 span {
  color: #b98e75;
  margin-right: 0.8rem;
  position: relative;
}
.slide-item-caption h3::after {
  content: "";
  position: absolute;
  background: url(../../assets/images/brush-obj1.png);
  background-repeat: repeat;
  background-size: auto;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  z-index: -1;
  height: 8.43rem;
  width: 20.188rem;
  top: 35%;
  right: -7%;
  transform: rotate(10deg);
  opacity: 0.6;
  animation: float-obj-x infinite 4s;
}
@keyframes float-obj-x {
  0% {
    transform: translateX(-30px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(-30px);
  }
}

.slide-item-caption p {
  color: #fff;
  position: relative;
  display: block;
  margin-bottom: 3.125rem;
  font-size: 1.25rem;
  line-height: 2.25rem;
  opacity: 0;
  max-width: 32.5rem;
  z-index: 11;
  -moz-transform: translateY(8.2rem);
  -o-transform: translateY(8.2rem);
  -ms-transform: translateY(8.2rem);
  -webkit-transform: translateY(8.2rem);
  transform: translateY(8.2rem);
  -moz-transition: all 1300ms ease;
  -webkit-transition: all 1300ms ease;
  -ms-transition: all 1300ms ease;
  -o-transition: all 1300ms ease;
  transition: all 1300ms ease;
  transition-delay: 0s;
}

.slide-item.active .slide-item-caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1300ms;
  -moz-transition-delay: 1300ms;
  -ms-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}
.btn-box {
  position: relative;
  opacity: 0;
  z-index: 11;
  -moz-transform: translateY(8.2rem);
  -o-transform: translateY(8.2rem);
  -ms-transform: translateY(8.2rem);
  -webkit-transform: translateY(8.2rem);
  transform: translateY(8.2rem);
  -moz-transition: all 1300ms ease;
  -webkit-transition: all 1300ms ease;
  -ms-transition: all 1300ms ease;
  -o-transition: all 1300ms ease;
  transition: all 1300ms ease;
  transition-delay: 0s;
}

.slide-item.active .slide-item-caption .btn-box {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1600ms;
  -moz-transition-delay: 1600ms;
  -ms-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

.carousel-control-next,
.carousel-control-prev {
  width: 3%;
  background: rgba(185, 142, 117, 0.2);
  border: 0.1rem solid rgba(185, 142, 117, 0.05);
  border-radius: 0;
  transition: all 300ms ease;
  height: 25%;
  top: 40%;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  background: rgba(185, 142, 117, 0.4);
  width: 3.5%;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 1.25rem;
}

@media only screen and (min-width: 1100px) and (max-width: 1500px) {
  .main-carousel.carousel {
    height: 70vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .main-carousel.carousel {
    height: 60vh;
  }
  .slide-item-caption h3 {
    font-size: 44.8px;
    line-height: 3.5rem;
    margin-bottom: 12.8px;
  }
  .slide-item-caption p {
    font-size: 1.25rem;
    line-height: 24px;
    margin-bottom: 33.92px;
  }
  .carousel .carousel-inner .slide-item .slide-item-caption {
    width: 70%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main-carousel.carousel {
    height: 60vh;
  }
  .slide-item-caption h3 {
    font-size: 31.8px;
    line-height: 2.5rem;
    margin-bottom: 12.8px;
  }
  .slide-item-caption p {
    font-size: 1.3rem;
    line-height: 24px;
  }
  .carousel .carousel-inner .slide-item .slide-item-caption {
    width: 100%;
  }
  .MuiButton-textPrimary.primary-btn {
    width: 171.2px !important;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .carousel .carousel-inner .slide-item .slide-item-caption {
    left: -5px;
  }
}
