.service-details-page {
  padding: 6.875rem 0 6.875rem 0;
}
.blog-standared-img {
  margin-bottom: 2.5rem;
}
.blog-standared-img img {
  width: 100%;
}
.service-details-page .service-detail-text h3 {
  margin-bottom: 1.875rem;
  font-size: 2.125rem;
  line-height: 1.2;
}
.service-details-page .service-detail-text p {
  margin-bottom: 2.5rem;
}
.service-detail-text .about-text-block {
  background: #fbf6f3;
  padding: 1.125rem 1.875rem;
  border-bottom: 0.125rem solid #b98e75;
  color: #2d3941;
  font-size: 1.25rem;
}
.faq-block .MuiPaper-root {
  top: auto !important;
  left: auto !important;
  width: auto;
}
.faq-block .accordion-item {
  margin-bottom: 0.9375rem;
  float: left;
  width: 100%;
  border: none;
}
.faq-block .accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.faq-block .accordion-item {
  background-color: #fff;
  background-color: #fff;
  border: none;
  box-shadow: none;
}
.faq-block .accordion-item .title {
  cursor: pointer;
  position: relative;
  background: #fbf6f3;
  margin: 0;
  padding: 0.3125rem 1.5625rem 0.3125rem 1.5625rem;
  font-size: 1.125rem;
  border: none;
  color: #2d3941;
  padding-left: 3.125rem;
}

.faq-block .accordion-item p {
  margin-bottom: 0;
}
.faq-block .accordion-item .title p {
  font-weight: 600;
}
.faq-block .accordion-item .MuiCollapse-root {
  background: #fbf6f3;
  border-top: 0.0625rem solid #f1ede9;
}

.faq-block .accordion-item .MuiAccordionSummary-content {
  margin: 0;
}

.faq-block .accordion-item .title::before {
  position: absolute;
  content: "\2192";
  width: 1.875rem;
  height: 1.875rem;
  background: #b98e75;
  left: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #fff;
}

.faq-block .accordion-item .Mui-expanded.title::before {
  content: "\2193";
}
.service-details-content .service-list {
  margin-top: 1.875rem;
}

.service-details-content ul li {
  margin: 0.9375rem 0;
}

.sidebar-widget {
  padding: 2.5rem 2.8125rem 2.5rem 2.8125rem;
  margin-bottom: 2.375rem;
  background: #fbf6f3;
}

.widget-title {
  margin-bottom: 2.5rem;
  font-size: 1.375rem;
  position: relative;
}
.sidebar-widget a {
  display: flex;
  align-items: center;
  color: #fff;
}

.sidebar-widget a svg {
  margin-right: 0.9375rem;
}

.brochure-download {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.widget-title::after {
  position: absolute;
  content: "";
  height: 0.25rem;
  width: 3.625rem;
  left: 0;
  bottom: -0.9375rem;
  background: #b98e75;
  border-radius: 0.375rem;
}

.sidebar-widget-need-help {
  padding: 2.8125rem;
  background: url(../../assets/images/contactimg.jpg);
  background-size: auto;
  background-size: cover;
  text-align: center;
  margin-bottom: 1.875rem;
  position: relative;
}

.sidebar-widget-need-help .need-help-icon {
  position: relative;
  z-index: 1;
  height: 4.75rem;
  width: 4.75rem;
  line-height: 4.75rem;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto 2.1875rem;
  box-shadow: 0 0 0rem 0.625rem #ffffff1c;
}
.sidebar-widget-need-help .need-help-title {
  position: relative;
  z-index: 1;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 1.875rem;
}
.sidebar-widget-need-help .need-help-contact {
  position: relative;
  z-index: 1;
  color: #fff;
}
.sidebar-widget-need-help::after {
  content: "";
  background: #b98e75;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.94;
  z-index: 0;
}

.sidebar-widget-need-help .need-help-contact a {
  font-size: 1.75rem;
  color: #fff;
  font-weight: 800;
}
.need-help-icon svg {
  color: #b98e75;
}

.service-nav-menu .service-link-list {
  background: #fbf6f3;
  padding: 2.5rem;
  margin-bottom: 1.875rem;
}

.service-nav-menu .service-link-list ul {
  margin: 0;
}
.service-nav-menu .service-link-list ul li a:hover {
  color: #fff;
}

.service-nav-menu .service-link-list ul li a {
  display: block;
  color: #2d3941;
  padding: 0.625rem 1.25rem;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  position: relative;
  z-index: 1;
  background: #f2eae6;
  margin-bottom: 0.625rem;
  font-weight: 500;
}

.service-nav-menu .service-link-list ul li a:hover::before {
  background-color: #b98e75;
  height: 100%;
  top: 0;
}
.service-nav-menu .service-link-list ul li a::before {
  content: "";
  width: 100%;
  left: 0;
  top: 50%;
  height: 0;
  position: absolute;
  transition: all 0.4s ease-out;
  z-index: -1;
}

@media only screen and (min-width: 20rem) and (max-width: 61.9375rem) {
  .sidebar-right {
    margin-top: 1.875rem;
  }
  .service-details-content img {
    width: 100%;
  }
  .service-details-page {
    padding: 2.875rem 0 2.875rem 0;
  }
  .service-nav-menu {
    margin: 2.5rem 2.5rem 0;
  }
  .service-detail-text .about-text-block {
    padding: 1.125rem 0.575rem;
  }
}
