.service {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 1.8125rem 1.375rem 1.5rem 1.375rem;
  z-index: 1;
  background: #fff;
  box-shadow: 0.375rem 0.375rem 1.5625rem 0 rgba(0, 0, 0, 0.08);
}

.service::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  -moz-transition: all 400ms ease;
  -webkit-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 0;
}

.service h4 {
  margin-bottom: 1.25rem;
  font-size: 1.375rem;
}
.services-count {
  position: absolute;
  top: -1.125rem;
  right: 1.4rem;
  font-size: 6.625rem;
  font-family: "Allison", cursive;
  opacity: 0.6;
  font-weight: 500;
  -webkit-text-stroke: 0.0625rem #b98e75;
  -webkit-text-fill-color: transparent;
}

.service:hover .services-count {
  color: none;
}
.service-description {
  margin-bottom: 1.25rem;
}

.services-link a {
  color: #b98e75;
  position: relative;
  padding-left: 0.9375rem;
  text-decoration: none;
  transition: all 300ms ease-out 0s;
}
.services-link a::after {
  background: #b98e75;
  position: absolute;
  content: "";
  height: 0.125rem;
  width: 0.625rem;
  left: 0;
  top: 0.9375rem;
  -moz-transition: all 400ms ease;
  -webkit-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.services-link:hover a {
  padding-left: 2.8125rem;
}
.service:hover a {
  color: #fff;
}
.services-link a:hover::after {
  width: 2.1875rem;
}

.service::after {
  background-image: url(../../assets/images/2.jpg);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: calc(100% + 2.5rem);
  height: 100%;
  background-size: cover;
  z-index: -1;
  opacity: 0;
  -moz-transform: translateX(-1.875rem);
  -o-transform: translateX(-1.875rem);
  -ms-transform: translateX(-1.875rem);
  -webkit-transform: translateX(-1.875rem);
  transform: translateX(-1.875rem);
  -moz-transition: all 400ms ease;
  -webkit-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.service:hover::after {
  opacity: 1;
  transform: translateX(0);
}

.service:hover::before {
  opacity: 0.8;
}

.service:hover h4,
.service:hover p {
  position: relative;
  color: #fff;
  z-index: 1;
}

.service-inner-obj::after {
  background: url(../../assets/images/inner-obj.png);
  background-repeat: repeat;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  height: 10.625rem;
  width: 10.625rem;
  bottom: 0;
  right: 0;
  animation: pulse infinite 1.5s;
  opacity: 0.4;
}

.service:hover .service-inner-obj::after {
  opacity: 0;
}

.service svg {
  fill: #b98e75;
  font-size: 3.125rem;
  margin-bottom: 1rem;
  z-index: 1;
  position: relative;
}

.service:hover svg {
  fill: #fff;
}
@media only screen and (min-width: 20rem) and (max-width: 62rem) {
  .service {
    margin-bottom: 1.875rem;
  }
  .before-after-container {
    margin-top: 1.5rem;
  }
  .service-container-header {
    flex-direction: column;
  }
}
