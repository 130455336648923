.card-container {
  display: flex;
  /* gap: 1.875rem; */
  margin-top: -11.25rem;
}
.before-after-container {
  margin-top: 6.875rem;
  position: relative;
  margin-bottom: -11.875rem;
  z-index: 1;
}
.before-after-container img {
  margin: 0 auto;
}

.slider {
  height: 100%;
  width: 0.3125rem;
  background: white;
  z-index: 26;
  position: relative;
}
.slider::before {
  position: absolute;
  content: "";
  background: #b98e75;
  height: 4.375rem;
  width: 3.125rem;
  margin-left: -1.875rem;
  margin-top: -2.5rem;
  position: absolute;
  top: 47%;
  margin-left: -1.375rem;
  margin-top: -1.375rem;
  border: 0.1875rem solid white;
  -webkit-border-radius: 62.5rem;
  -moz-border-radius: 62.5rem;
  border-radius: 62.5rem;
  -webkit-box-shadow: 0rem 0rem 0.75rem rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0rem 0rem 0.75rem rgba(51, 51, 51, 0.5);
  box-shadow: 0rem 0rem 0.75rem rgba(51, 51, 51, 0.5);
  z-index: 40;
  cursor: pointer;
}
.slider::after {
  content: "A";
  position: absolute;
  top: 51%;
  margin-left: -0.5625rem;
  margin-top: -2.3125rem;
  z-index: 40;
  color: #fff;
  font-size: 2.1875rem;
  cursor: pointer;
}
/* .before-after-container div:nth-child(2)::before {
  transition-property: background;
  background: rgba(0, 0, 0, 0);
  z-index: 25;
  transition-duration: 0.5s;
  position: absolute;
  top: 0;
  width: 78%;
  height: 100%;
  margin: 0 auto;
  left: 10.7%;
}

.twentytwenty-overlay:hover {
  background: rgba(0, 0, 0, 0.5);
} */

@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .card-container {
    width: 100%;
  }
  .service {
    margin-bottom: 1.875rem;
  }
}
