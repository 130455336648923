.latest-project-container {
  position: relative;
  background: url("../../assets/images/abs-bg3.png") no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding-top: 2.563rem;
  background-color: #252531;
  overflow: hidden;
}
.latest-project-container .carousel {
  height: 39.3vh;
}
.latest-project-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.4;
}

.top-heading {
  display: flex;
  align-items: center;
}
.top-heading h2 {
  color: #fff;
  width: 70%;
  letter-spacing: 0.125rem;
}
.top-heading p {
  color: #fff;
  margin: 0;
  text-align: end;
  width: 43%;
}

.backbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  height: 4.375rem;
  left: 2.8125rem;
  line-height: 3.125rem;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 3.125rem;
  background: #b98e75;
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -moz-transition: all 600ms ease 0ms;
  -webkit-transition: all 600ms ease 0ms;
  -ms-transition: all 600ms ease 0ms;
  -o-transition: all 600ms ease 0ms;
  transition: all 600ms ease 0ms;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  color: #fbf6f3;
}
.carousel:hover .backbtn {
  left: 0.3125rem;
}
.carousel:hover .backbtn {
  opacity: 1;
  visibility: visible;
}
.mlcustom {
  margin-left: 29.6875rem;
}

@media only screen and (min-width: 62rem) and (max-width: 75rem) {
  .latest-project-container .carousel {
    height: 31.3vh;
  }
}
@media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  .mlcustom {
    margin-left: 18rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .mlcustom {
    margin: 0 auto;
  }
  .project-item-style1:nth-child(2) {
    margin: 0 !important;
  }
  .carousel:hover .backbtn {
    display: none;
  }

  .heading-box .heading {
    padding-left: 4.375rem;
  }
}
@media only screen and (min-width: 20rem) and (max-width: 62rem) {
  .project-item-style1:hover .project-item-thumb .project-item-details {
    bottom: 0.25rem !important;
  }
  .top-heading {
    flex-direction: column;
    margin-bottom: 1.5625rem;
  }
  .top-heading p {
    width: 100%;
  }
  .heading-box h2 {
    margin-bottom: 1.5rem;
    width: 100%;
  }
}
@media only screen and (min-width: 47.9375rem) and (max-width: 62rem) {
  .project-item-style1:hover .project-item-thumb .project-item-details {
    bottom: -0.75rem !important;
  }
}
