.categories-container {
  background: #b98e75;
  position: relative;
  width: 93%;
  margin: -5rem auto;
  z-index: 1;
}
.feature-box {
  display: flex;
  justify-content: space-around;
  padding: 2.81rem 2.81rem 1.56rem 2.81rem;
  background: url(../../assets/images/feature-obj1.png);
}
.categories-container .category {
  position: relative;
  background: #ad836a;
  padding: 1.87rem 1.87rem 1.56rem 1.87rem;
  overflow: hidden;
  height: 100%;
}

.feature-box .col-lg-2 {
  margin-bottom: 1.25rem;
}
.categories-container .category::before {
  position: absolute;
  top: 0;
  left: -80%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
  z-index: 1;
}

.category:hover::before {
  -webkit-animation: shine 1.3s;
  animation: shine 1.3s;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.inner-box {
  position: relative;
  text-align: center;
}
.icon-box {
  position: relative;
  display: inline-block;
  background: #fff;
  padding: 1.125rem 1.25rem;
}
.icon-box svg {
  font-size: 3rem;
  fill: #b98e75;
  opacity: 0.8;
}

.title {
  position: relative;
  margin-top: 0.938rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

@media only screen and (min-width: 20rem) and (max-width: 47.9375rem) {
  .feature-box .col-md-4:nth-child(even) .category {
    margin-right: 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .categories-container {
    margin: 0 auto;
  }
}
