.MuiButton-textPrimary.primary-btn {
  position: relative;
  display: inline-block;
  color: #2d3941;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  z-index: 2;
  letter-spacing: 0.063rem;
  border: 0.1rem solid #b98e75;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  padding: 0.8rem 2rem;
  font-size: 0.9rem;
  border-radius: 0;
}

.primary-btn::before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  background: rgba(185, 142, 117, 0.9);
  left: 0;
  top: 0;
  z-index: -1;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}

.primary-btn::after {
  content: "";
  height: 1rem;
  width: 1rem;
  position: absolute;
  background: rgba(185, 142, 117, 0.9);
  bottom: 0;
  right: 0;
  z-index: -1;
  -moz-transition: all 300ms ease-out 0s;
  -webkit-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}

.primary-btn.secondary-btn::before,
.primary-btn.secondary-btn::after {
  background: #000;
}
.primary-btn:hover {
  color: #fff;
}

.primary-btn:hover::before,
.primary-btn:hover::after {
  width: 100%;
  height: 100%;
  background: #b98e75;
}
.primary-btn.secondary-btn:hover::before,
.primary-btn.secondary-btn:hover::after {
  background: #000;
  width: 100%;
  height: 100%;
}
